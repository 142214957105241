import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Container from './Container'
import CanadaHelps from '../svg/CanadaHelps'

const Footer = ({ siteTitle }) => (
  <footer className="mt-32">
    <Container size="medium">
      <div className="text-center mb-10">
        <a href="https://www.canadahelps.org/en/pages/in-loving-memory-10/" rel="noopener noreferrer" target="_blank">
        <CanadaHelps width="200px" blueFill="var(--color-primary)" blackFill="var(--color-primary)"   yellowFill="var(--color-primary)" />
        </a>
      </div>
      <div className="text-center">
        © {new Date().getFullYear()} {siteTitle}.
      </div>
      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
    </Container>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
