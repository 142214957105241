import React from 'react'
import PropTypes from 'prop-types'
import * as containerStyles from '../styles/container.module.scss'

const Container = ({ children, size, className }) => {


	const getStyle = [
		(size && size === 'small') ? ( containerStyles.containerSm )
		: (size && size === 'medium') ? ( containerStyles.containerMd )
		: (size && size === 'large') ? ( containerStyles.containerFluid )
		: ( containerStyles.containerFluid )
	]

	return (
		<div className={`${containerStyles.containerMain} ${getStyle} ${className}`}>
			{children}
		</div>
	)
}

Container.propTypes = {
	children: PropTypes.node.isRequired,
	size: PropTypes.string,
	className: PropTypes.string,
}

Container.defaultProps = {
	size: ``,
	className: ``,
}

export default Container
