import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Container from './Container'
import { StaticImage } from 'gatsby-plugin-image'
import { useMediaQuery } from 'react-responsive'
// import Button from './Button'

const TropicalBack = () => {

  const aboveLg = useMediaQuery({ query: '(min-width: 1024px)' })

  return (
    <div className="top-0 left-0 right-0 bottom-auto absolute h-auto md:block hidden w-full" style={{top: aboveLg ? '-1rem' : '-0.5rem'}}>
      <StaticImage
      src="../images/tropical_coral_backing.png"
      alt="Coral theme"
      className="absolute w-full h-auto block overflow-visible"
      imgStyle={{objectFit: 'cover', width: '100vw', height: 'auto'}}
      quality={86}
      />
    </div>
  )
}

const Header = ({ minimal, siteTitle }) => {

  return (
    <header className={`mb-12 ${minimal ? 'p-0' : 'p-4'}`}>

      <div className="bg-transparent md:mt-10">
      <TropicalBack />
      <Container size="small" className="py-5 pb-10 border-b border-gray-200">
        {minimal ? (
          <div className="flex flex-row justify-center items-center">
          <h1
          style={{ margin: 0 }}
          className="text-xl"
          >
            <Link
              to="/"
              style={{
                color: `var(--color-black)`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>
          </div>
        ) : (
          <div className="flex flex-col justify-center items-center text-center relative z-40">
          <h1
          style={{ margin: 0 }}
          className="text-5xl md:text-7xl"
          >
            <Link
              to="/"
              style={{
                color: `var(--color-black)`,
                textDecoration: `none`,
              }}
            >
              {siteTitle}
            </Link>
          </h1>

          <div className="mt-10 flex-trueauto text-center space-x-8 md:space-x-7 lg:space-x-10 xl:space-x-16 2xl:space-x-20">
          <AnchorLink offset='90' href="#about">Her Life</AnchorLink>
          <AnchorLink offset='90' href="#photos">Gallery</AnchorLink>
          <AnchorLink style={{fontSize: '1.05rem'}} className="hidden md:inline-block text-white font-sans font-bold bg-primary-default hover:bg-primary-lighter p-4" offset='140' href="#give">Donate in Deb's Honour</AnchorLink>
          </div>

          <div className="md:hidden block mt-7">
            <AnchorLink style={{fontSize: '1.05rem'}} className="inline-block text-white font-sans font-bold bg-primary-default hover:bg-primary-lighter p-4" offset='140' href="#give">Donate in Deb's Honour</AnchorLink>
          </div>

          </div>
        )
      }
      </Container>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
