import React from 'react'

const CanadaHelps = props => {

	const {blueFill, yellowFill, grayFill, blackFill, width, ...rest} = props;

	let blue = `#1466A9`;
	let yellow = `#F5BD3E`;
	let gray = `#687281`;
	let black = `#0070AE`;

	if (blueFill) {
		blue = blueFill;
	}

	if (yellowFill) {
		yellow = yellowFill;
	}

	if (grayFill) {
		gray = grayFill;
	}

	if (blackFill) {
		black = blackFill;
	}

	const fillBlue = {
		fill: blue,
		fillRule: `evenodd`,
		clipRule: `evenodd`,
	}

	const fillYellow = {
		fill: yellow,
		fillRule: `evenodd`,
		clipRule: `evenodd`,
	}

	const fillGray = {
		fill: gray,
		fillRule: `evenodd`,
		clipRule: `evenodd`,
	}

	const fillBlack = {
		fill: black,
		fillRule: `evenodd`,
		clipRule: `evenodd`,
	}
	
	const iconStyle = {
		width: width,
		height: 'auto',
		display: 'inline-block',
	}

	return (

		<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 	viewBox="0 0 650 360" style={iconStyle} {...rest}>
		<g id="icon-_x2F__xD83D__xDCF1_-logo">
		<g id="Group">
		<path id="Fill-1" style={fillBlue} d="M99.7,255.8c17-22.4,21.6-48.9,17-66.7C102.4,209,95.5,230.8,99.7,255.8L99.7,255.8z M0,333.4
			c0.8-0.5,0.7-1.5,1-2.3c1.2-3,3-5.1,6-6.6c21.1-10.6,42-21.7,60.9-36.1c7-5.3,13.5-11,19.5-17.4c1-1.1,1.5-2.1,1.1-3.6
			c-8-30.3-0.8-57.7,15.7-83.3c2.2-3.5,4.7-6.8,7.2-10c1.2-1.5,1.3-2.7,0.6-4.4c-7.3-15.6-18.2-27.7-33.1-36.3
			c-10.1-5.8-20.8-10-31.5-14.5c-0.5-0.2-1.1-0.4-1.6-0.6c-9.4-4-11.5-8.2-9.2-18c1.6-7,5.8-9.7,12.9-8.3c3.3,0.7,6.4,1.9,9.4,3.4
			c28.8,14.5,49.3,37.1,63.5,65.8c1.6-0.9,2.7-2.5,4.1-3.7c15.6-14.4,33.5-25.4,51.8-35.9c12.6-7.2,25.4-14.2,38.1-21.3
			c2.8-1.6,5.5-0.3,6.9,3.3c1.8,4.5-1.2,11.2-6.1,13.4c-9.5,4.3-18.9,8.9-28.1,13.7c-18.4,9.7-36.2,20.5-51.5,34.7
			c-3,2.8-5.8,5.9-8.7,8.7c-0.9,0.9-1.3,1.8-1,3.1c8.5,33.4-0.7,62.8-20.5,89.6c-3.2,4.3-3,7.3-0.7,11.6
			c9.7,18.2,24.3,30.7,42.8,39.2c8.3,3.8,16.7,7.1,25.1,10.5c2.7,1.1,5.5,2.3,7.8,4.2c2.5,2,3.8,4.6,3.4,7.8
			c-0.5,3.9-1.5,7.7-3.6,11.1c-3.4,5.3-9.5,7-16.3,4.8c-7.8-2.5-14.4-7.1-20.7-12.1c-17.4-13.9-33.2-29.2-44.7-48.5
			c-2-3.4-3.8-6.9-5.5-10.4c-0.7-1.5-1-2-2.7-0.6c-23,19.7-47.8,36.7-74.2,51.5c-3.6,2-7.3,3.7-11.3,4.9c-2.7,0.9-5,0.7-6.8-1.8
			C0,339,0,333.4,0,333.4z"/>
		<path id="Fill-4" style={fillYellow} d="M97.1,67.3c0,6.1,1.1,11.9,3.2,17.5c8.5,23.7,34.5,34.1,56.8,22.6C181,95,191.4,63.6,179.4,39.5
			c-10-20.1-32.8-28.4-53-18.7C106.9,30.1,98.4,46.6,97.1,67.3 M199.5,65.7c-1.1,20.7-11.2,41.7-33.8,55.1
			c-30.7,18.2-67,5.4-79.4-28.1C76.6,66.5,82,42.4,99.8,21.2c10.6-12.6,24.4-20.1,41-21.1c20.8-1.2,37.2,7.4,48.6,24.8
			C196.6,35.6,199.7,47.7,199.5,65.7"/>
		<path id="Fill-7" style={fillGray} d="M606.6,273.4c0.1-4.1-0.7-7.7-2.7-11c-4.5-7.6-14.4-8-19.2-0.7c-3.8,5.8-4.4,12.1-1.9,18.6
			c1.9,4.8,5.1,8.1,10.6,8.2c5.6,0.1,9.3-2.8,11.7-7.7C606.3,278.4,606.8,275.8,606.6,273.4 M606.9,257.1c0.2-0.6,0.4-0.8,0.4-1
			c0.4-5.4,0.4-5.4,5.9-5c1.3,0.1,1.6,0.6,1.6,1.7c0,14.3,0.2,28.6-0.2,42.8c-0.3,11.8-7.7,18.2-20.1,18.7
			c-5.4,0.2-10.7-0.6-15.8-2.2c-1.9-0.6-2.4-1.4-2.1-3.4c0.7-4.7,0.5-4.7,5-3c4.2,1.6,8.5,2.4,12.9,1.9c6.6-0.7,10.4-4.1,11.8-10.6
			c0.6-2.6,0.6-5.3,0.4-7.8c-0.7-0.3-1,0.2-1.3,0.4c-5.8,5.5-12.6,6.7-19.9,4.4c-7.2-2.3-10.7-8.1-12-15.2s-0.3-13.9,3.5-20.2
			c5-8,15.4-11.1,24-7.2C603.3,252.7,605.2,254.5,606.9,257.1"/>
		<path id="Fill-9" style={fillBlue} d="M284.4,265.3c0,9.2,0,18.4,0,27.6c0,1.9-0.5,2.4-2.4,2.5c-6.3,0-6.3,0.1-6.3-6.3
			c0-6.3-0.1-12.6,0-19c0-1.9-0.5-2.5-2.4-2.5c-8,0.1-16.1,0.1-24.1,0c-2.1,0-2.5,0.8-2.5,2.7c0.1,7.5,0,14.9,0.1,22.4
			c0,2-0.5,2.6-2.5,2.6c-6.3-0.1-6.3,0-6.3-6.2c0-17.2,0-34.5,0-51.7c0-2.1,0.4-2.8,2.7-2.7c6.2,0.1,6.2,0,6.2,6.3
			c0,5.5,0.1,10.9,0,16.4c0,1.9,0.3,2.7,2.5,2.7c8-0.1,16.1-0.1,24.1,0c1.9,0,2.4-0.6,2.4-2.5c-0.1-6.8,0-13.6-0.1-20.4
			c0-2.1,0.6-2.5,2.6-2.5c6.1,0.1,6.1,0,6.1,6.2v24.4H284.4z"/>
		<path id="Fill-11" style={fillGray} d="M382.9,201.8c-0.2,3.4,0.6,6.7,2.1,9.9c2,4,4.9,6.8,9.7,7c4.8,0.2,8.4-1.9,10.7-6
			c4-7.1,3.9-14.3-0.1-21.3c-2.4-4.2-6.4-6.2-11.4-5.7c-4.7,0.4-7.6,3.2-9.4,7.4C383.2,195.8,382.7,198.7,382.9,201.8 M408.2,185.3
			c0-7.8,0.1-15.7,0-23.5c0-2,0.5-2.6,2.5-2.5c5.5,0.1,5.5,0,5.5,5.5c0,18.9,0,37.7,0.1,56.6c0,2.2-0.6,2.9-2.8,2.8
			c-4.6-0.2-4.6,0-5.3-5.2c-0.9-0.3-1.1,0.6-1.5,1c-7.8,7.6-24.5,8.1-30.1-6.4c-3-7.9-3.3-15.9,0-23.8c5.2-12,21.1-14.8,30.1-5.4
			c0.3,0.3,0.5,0.8,0.8,1.2C407.7,185.5,408,185.4,408.2,185.3"/>
		<path id="Fill-13" style={fillBlue} d="M405.8,272.3c0.1-2.7-0.5-5.8-1.8-8.7c-1.9-4.2-5.1-6.7-9.7-7c-4.3-0.2-7.8,1.5-10.3,5.1
			c-4.5,6.3-4.5,16.4-0.1,22.7c5,7.2,14.4,7,19-0.4C405,280.6,405.9,276.8,405.8,272.3 M380.6,289.7c0,7.1-0.1,14,0.1,20.8
			c0,2.2-0.5,2.9-2.8,2.8c-5.4-0.2-5.4,0-5.4-5.4c0-18.1,0-36.2,0-54.3c0-2.1,0.5-3,2.7-2.7c1.6,0.3,3.7-0.8,4.9,0.6
			c1,1.1-0.2,3.1,0.7,4.9c2.8-2.9,5.8-5,9.6-6c11.2-2.9,20.8,3.1,23.5,14.8c1.7,7.6,1.1,15.1-2.8,22c-6,10.7-20.9,12.2-29.3,3.1
			C381.4,290.2,381.2,290.1,380.6,289.7"/>
		<path id="Fill-15" style={fillGray} d="M217.1,173.4c-13.8-5.1-24.3-3.2-30.9,5.7c-6.2,8.3-6.3,21.3-0.1,29.6c6.7,9,17.6,11.2,30.9,6.4
			c0.1,0.1,0.3,0.3,0.3,0.4c0.7,8.9,2.2,7.8-7.1,9.3c-6.2,1-12.3,0.5-18.1-1.5c-11.3-3.9-17.7-12.1-19.3-23.8
			c-1.7-12.6,1.3-23.8,12.5-31.2c9.5-6.4,20-6.9,30.8-3.6c1.3,0.4,1.8,1.1,1.6,2.5C217.5,169.2,217.3,171.3,217.1,173.4"/>
		<path id="Fill-17" style={fillBlue} d="M316.5,268.9c3.1,0,6.3-0.1,9.4,0c1.7,0.1,2.1-0.4,1.9-2.1c-0.7-6.1-5.2-10.4-10.9-10.4
			c-5.7,0-10.8,4.8-11.5,10.8c-0.1,1.2,0.1,1.7,1.5,1.7C310,268.9,313.3,268.9,316.5,268.9 M333.2,285.4c0,1.3,0,2,0,2.8
			c0,5.5,0,5.6-5.3,7c-5.5,1.4-11,1.5-16.5,0.1c-6.3-1.6-10.9-5.5-13.1-11.8c-2.7-7.6-2.9-15.2,0.6-22.6
			c4.9-10.2,18.5-14.2,28.3-8.6c5.9,3.4,11,15.1,9.4,21.6c-0.4,1.5-1.5,1-2.3,1c-8.5,0-17,0-25.6,0c-4,0-4.2,0.3-3,4.3
			c2.1,7.2,9.1,11.5,16.5,10.3C326,288.9,329.6,287.5,333.2,285.4"/>
		<path id="Fill-19" style={fillGray} d="M350.2,202.9c-3,0.1-7.1,0.3-10.7,2.2c-3.1,1.6-4.6,4.2-4.3,7.1c0.3,2.9,2.8,5.4,6,6.2
			c7.2,1.8,13.8-2.9,14.7-10.2C356.4,203,356.3,203,350.2,202.9 M332.1,189.6c-0.8-7.8-1.3-6.4,5.1-9c4.6-1.9,9.5-1.9,14.4-1
			c6.7,1.2,10.4,5.4,11.7,12.1c0.7,3.9,0.4,7.8,0.5,11.7c0.1,6.3,0,12.6,0.1,18.9c0,1.4-0.2,1.8-1.6,1.9c-5.1,0.2-5.1,0.3-6.1-5.3
			c-2.9,3.1-6.1,5.3-10.2,6c-3.9,0.7-7.7,0.3-11.3-0.9c-4.8-1.7-7.5-5.2-8.1-10.2s0.8-9.3,5-12.4c4.4-3.2,9.5-4.3,14.9-4.4
			c2.4,0,4.8,0,7.2,0.1c1.5,0.1,2.1-0.4,2-1.9c-0.1-5.7-3.5-9.3-9.3-9.6C341.6,185.3,339.1,186,332.1,189.6"/>
		<path id="Fill-21" style={fillGray} d="M452.6,202.9c-4.5,0-9.2,0.9-11.7,2.3c-2.5,1.5-4.1,3.6-3.9,6.5c0.1,2.8,1.7,4.8,4.3,6
			c7,3.4,15.8-1.7,16.3-9.5C457.9,202.9,457.9,202.9,452.6,202.9 M434.2,189.6c-0.2-0.3-0.3-0.5-0.3-0.6c-0.5-6.8-1.8-5.6,5.1-8.3
			c4.7-1.9,9.7-2,14.7-1.1c7.4,1.4,11.6,6.6,11.9,14.8c0.4,9.5-0.3,19,0.3,28.4c0.1,1-0.3,1.4-1.2,1.4c-0.3,0-0.6,0-0.9,0
			c-5.1,0.1-5.1,0.1-5.6-5.2c-3.1,3.2-6.5,5.4-10.8,6.1c-3.8,0.5-7.5,0.1-11-1.2c-4.7-1.8-7.3-5.4-7.8-10.4
			c-0.5-5.2,1.1-9.5,5.5-12.4s9.3-3.9,14.5-4c2.3,0,4.6-0.1,6.9,0.1c1.9,0.2,2.3-0.6,2.2-2.3c-0.5-7-5.8-10.5-14-9.1
			C440.2,186.3,437.1,187.7,434.2,189.6"/>
		<path id="Fill-23" style={fillGray} d="M254.7,206.2c-0.7-0.7,1.1-3-1.1-3.2c-5-0.4-10.1-0.4-14.9,1.8c-3.4,1.6-5.1,4.5-4.6,7.4
			c0.6,3.3,3.3,5.7,7,6.2C249,219.6,254.6,214.6,254.7,206.2 M255.1,219.1c-5.9,6.3-13,7.4-20.7,5.1c-4.8-1.4-7.8-4.6-8.6-9.7
			c-0.9-5.2,0.3-9.7,4.7-13c4.5-3.4,9.7-4.4,15.1-4.5c2.4,0,4.8,0,7.2,0.2c1.6,0.1,1.9-0.4,1.9-1.9c-0.2-5.7-3.6-9.3-9.3-9.6
			c-5.2-0.3-9.8,1.1-14,4c-0.2-0.2-0.4-0.4-0.4-0.5c-0.8-6.3-0.7-6.5,5.5-8.6c4.6-1.6,9.4-1.8,14.1-0.9c7.2,1.3,11.5,6.2,11.9,14.1
			c0.5,9.7-0.3,19.5,0.4,29.3c0.1,0.8-0.2,1.3-1.1,1.3c-1.7,0-3.4,0-5.2,0c-0.9,0-1.1-0.5-1.1-1.3
			C255.6,221.7,255.9,220.6,255.1,219.1"/>
		<path id="Fill-25" style={fillGray} d="M522.1,272.7c0.2-1.3-0.2-3.2-0.7-5c-1.7-6.5-7-11.1-12.9-11.2c-5.8-0.1-11,4-13.1,10.5
			c-1.8,5.7-1.3,11.1,1.7,16.3c4.7,8.2,16.2,8.7,21.6,1.1C521.1,281.1,522.1,277.4,522.1,272.7 M530.6,273
			c0,13.4-9.5,23.2-22.4,23.1c-12.8-0.1-22.3-9.9-22.3-23c0-13.6,9.1-23,22.4-23.1C521.5,250,530.6,259.3,530.6,273"/>
		<path id="Fill-27" style={fillGray} d="M284.6,186.5c5.4-7.1,12.2-8.7,19.9-6.8c6.7,1.6,10.6,6.8,10.9,15.1c0.3,9.2,0.1,18.4,0.2,27.6
			c0,1.4-0.6,1.9-1.9,1.7c-0.1,0-0.2,0-0.3,0c-5.9,0.2-5.8,0.2-5.9-5.7c-0.1-7.7,0.5-15.5-0.5-23.2c-0.7-5.7-3.2-8.8-7.6-9.5
			c-4.9-0.8-9.8,1.6-12.3,6.1c-1.9,3.5-2.5,7.2-2.5,11.1c-0.1,6.3-0.1,12.6,0,19c0,1.9-0.6,2.4-2.4,2.4c-5.7,0-5.7,0-5.7-5.6
			c0-11.9,0-23.7,0-35.6c0-2.1,0.4-3,2.8-2.9C284.3,180.2,284.3,180.1,284.6,186.5"/>
		<path id="Fill-29" style={fillBlue} d="M447.7,258.7c-3.8-1.2-7.4-2.7-11.3-1.7c-2.4,0.6-4.4,1.6-4.9,4.2s0.8,4.4,2.8,5.8
			c2.7,1.9,5.8,2.8,8.7,4.4c5.8,3.2,8.1,7.2,7.4,13c-0.8,6.4-4.9,10.6-11.6,11.7c-4.5,0.7-9,0.5-13.3-1c-2-0.7-3.5-6.2-2-7.7
			c0.7-0.7,1.2,0.1,1.8,0.3c3.7,1.5,7.3,2.8,11.4,1.7c2.7-0.7,4.7-2.1,5.1-5.1c0.4-2.8-1.2-4.5-3.4-5.8c-2.5-1.5-5.1-2.7-7.6-4
			c-7-3.8-9.1-7.6-7.7-14.2c1.2-5.6,6.1-9.5,13.1-10c3.5-0.3,6.9,0.3,10.2,1.1c1.6,0.4,2.3,1,1.9,2.7
			C447.9,255.3,447.9,256.9,447.7,258.7"/>
		<path id="Fill-31" style={fillBlue} d="M348.9,262.4c0-10,0.1-20.1,0-30.1c0-1.9,0.5-2.4,2.4-2.4c6.3,0,6.3,0,6.3,6.2
			c0,18.9,0,37.9,0.1,56.8c0,2.2-0.5,2.7-2.7,2.7c-6-0.1-6,0-6-5.9L348.9,262.4L348.9,262.4z"/>
		<path id="Fill-33" style={fillGray} d="M550.8,257.3c3.4-5.2,7.6-7.8,13.5-7.3c2.2,0.2,3.8,0.6,3.2,3.4c-0.2,0.9-0.1,1.9,0,2.9
			c0.1,1-0.2,1.2-1.3,1.2c-9.3-0.5-14.3,3.8-14.9,13.5c-0.4,7.1-0.3,14.2-0.2,21.2c0,2.3-0.5,3.1-3,3.1c-5.3-0.2-5.3,0-5.3-5.3
			c0-12,0-23.9,0-35.9c0-2.2,0.4-3,2.8-2.9c4.6,0.2,4.6,0,4.9,4.8C550.5,256.2,550.6,256.5,550.8,257.3"/>
		<path id="Fill-35" style={fillBlue} d="M251.7,336.4c0.1-1.4-0.3-3-1-4.4c-1.1-2.3-2.8-3.7-5.5-3.8c-2.7,0-4.3,1.4-5.3,3.6
			c-1.6,3.4-1.7,6.8,0.4,10.1c2.1,3.2,6,3.7,8.9,1.2C251.1,341.4,251.8,339.2,251.7,336.4 M252.3,328.1c-0.6-2.9,0.9-2.8,2.4-2.8
			c2,0,1.3,1.6,1.3,2.5c0.1,6.2,0.1,12.4,0,18.7c0,8-3.7,11.6-11.6,11.7c-2.3,0-4.5-0.5-6.8-1.1c-1.1-0.3-1.5-0.8-1.5-2
			c0-1.9,0.4-2.7,2.4-1.7c1,0.5,2.2,0.7,3.3,1c6.6,1.3,10.3-2.1,9.7-9c-2.2,1.3-4.2,2.6-6.8,2.8c-4.2,0.3-7.7-1.7-9.4-5.6
			c-1.3-3-1.3-6.2-0.6-9.3C236.8,324,246.5,322.3,252.3,328.1"/>
		<path id="Fill-37" style={fillBlue} d="M358.3,336.8c-0.2-1.6-0.2-2.9-0.7-4.2c-1.1-2.5-2.7-4.3-5.7-4.4c-3.1-0.1-4.7,1.7-5.7,4.3
			c-1.2,3-1.2,6,0.4,8.8c2.4,4.4,7.7,4.5,10.5,0.3C358,340.1,358.2,338.3,358.3,336.8 M358.1,345c-2.6,2.2-5.2,3.3-8.2,3
			c-7-0.5-10.9-7.4-8.6-15.4c2.3-8.2,10.4-10.7,16.8-5.1c1.2-0.3-0.3-2.2,1.4-2.3c1.7,0,3.1-0.3,3,2.3c-0.2,6.2,0,12.4-0.1,18.7
			c0,8.4-4.5,12.5-12.9,11.7c-1.7-0.2-3.4-0.7-5.1-0.9c-2-0.3-2.1-1.5-1.8-3.1c0.2-1.3,0.7-1.3,1.8-0.9c1.1,0.5,2.3,0.8,3.5,1.1
			C354.8,355.8,358.5,352.6,358.1,345"/>
		<path id="Fill-40" style={fillBlue} d="M387.9,327.9c5.3-4.6,9.6-4.3,13.9,0.6c2-2.4,4.3-4,7.6-3.8c3.8,0.2,6.2,2,6.9,5.6
			c1.1,5.5,0.3,11.1,0.5,16.6c0.1,1.8-1.4,1.1-2.3,1.2c-1.1,0.1-2.1,0.1-2.1-1.5c0.1-3.6,0-7.3,0-10.9c0-1.1,0-2.1-0.1-3.2
			c-0.2-2-0.8-3.9-3.1-4.3c-2.3-0.4-4.1,0.5-5.3,2.5c-0.9,1.5-1.4,3.2-1.4,4.9c0,1.8,0,3.6,0,5.5c0,7-0.1,7.3-2,6.9
			c-4.7-1-1.5-4.7-2.1-7.1c-0.7-2.8,0.2-5.9-0.4-8.8c-0.4-1.9-1-3.6-3.2-3.9s-4,0.5-5.1,2.4c-1.1,1.7-1.5,3.7-1.5,5.7
			c0,3.2-0.1,6.3,0,9.5c0.1,2-0.7,2.4-2.5,2.3c-1.2-0.1-1.7-0.3-1.7-1.6c0-6.6,0-13.2,0-19.8c0-1.1,0.4-1.4,1.5-1.5
			C387.2,325.1,388.2,325.5,387.9,327.9"/>
		<path id="Fill-42" style={fillBlue} d="M553.6,327.8c6-4.4,9.7-4.2,13.9,0.7c2-2.4,4.5-4,7.8-3.8c4.2,0.2,7,2.9,7.1,7.1
			c0.1,4.8,0,9.6,0.1,14.4c0,1.8-0.8,2-2.2,2c-1.5,0-2-0.4-2-2c0.1-4.2,0.1-8.4,0-12.6c-0.1-4.1-2.2-6.1-5.3-5.3
			c-2.7,0.7-3.8,2.7-4.3,5.2c-0.8,4.1-0.3,8.2-0.4,12.3c0,1.6-0.2,2.4-2.1,2.4c-1.9,0-2.2-0.8-2.1-2.4c0.1-3.9,0.1-7.8,0-11.8
			c-0.1-3.8-1.3-5.7-3.7-5.8c-2.9-0.2-5.3,1.7-5.9,5.1c-0.7,4-0.2,8-0.3,12c0,1.6,0.2,2.9-2.3,2.9s-2.2-1.4-2.2-2.9
			c0-5.7,0-11.5,0.1-17.2c0-1-1-2.7,1.2-2.8C552.4,325.3,554.4,324.6,553.6,327.8"/>
		<path id="Fill-44" style={fillBlue} d="M466.4,336.3c-0.1-1.4-0.3-2.9-1-4.3c-1.3-2.6-3.3-4.1-6.4-3.8c-2.9,0.3-4.4,2.2-5.1,4.7
			c-1,3.5-0.8,7,1.6,10.1c2.3,2.9,6.3,2.9,8.7,0.2C465.9,341.3,466.4,338.9,466.4,336.3 M466.4,327.7c0-4,0.1-7.7,0-11.3
			c-0.1-1.8,0.7-2,2.2-2c1.6,0,2.1,0.5,2.1,2.1c-0.1,9.9,0,19.7,0,29.6c0,0.9,0.3,2.2-1.2,2c-1-0.1-2.5,0.9-2.8-1
			c-0.2-1.5-0.6-1.2-1.6-0.6c-7.5,4.5-14.1,1.6-15.8-7c-0.6-2.8-0.4-5.5,0.5-8.2C452,324.4,459.5,322.6,466.4,327.7"/>
		<path id="Fill-46" style={fillBlue} d="M607.7,336.6c-0.1-1-0.1-1.9-0.2-2.9c-0.7-3.5-2.8-5.4-5.7-5.5c-3.1-0.1-5.5,1.6-6.5,4.9
			c-0.7,2.2-0.7,4.5-0.1,6.8c0.9,3.1,3.3,5.2,6,5.4c2.6,0.1,4.8-1.8,5.9-5C607.6,339.1,607.7,337.8,607.7,336.6 M594.6,345.4
			c0,3.9-0.1,7,0,10.2c0.1,1.8-0.7,2-2.2,2c-1.6,0-2.1-0.4-2.1-2.1c0.1-9.3,0-18.6,0.1-27.9c0-1-0.7-2.5,1.4-2.4
			c1.5,0.1,3.5-0.6,2.4,2.7c2.4-1.6,4.2-3,6.5-3.3c6.3-0.8,10.9,3.3,11.4,10.3c0.2,2.4-0.2,4.8-1,7.1c-2.4,6.6-9.6,8.7-15.2,4.4
			C595.8,346.3,595.5,346.1,594.6,345.4"/>
		<path id="Fill-48" style={fillBlue} d="M639.6,334.5c1.4,0,2.9-0.1,4.3,0c1.5,0.1,1.8-0.5,1.4-1.9c-0.7-2.3-1.9-4-4.4-4.3
			c-2.7-0.4-4.9,0.5-6.2,3c-1.4,2.7-1.2,3.2,1.8,3.2C637.5,334.5,638.6,334.5,639.6,334.5 M648,343.2c0.6,4,0.4,4.3-3.1,5
			c-7.6,1.6-13.5-1.3-15.2-7.6c-1.9-7.1,0.9-13.8,6.8-15.4c9.5-2.5,13.3,3.6,13.5,11c0,1.6-1,1.3-2,1.3c-4,0-8,0.1-12.1,0
			c-2.2-0.1-2.4,0.8-1.8,2.6c1.1,3.3,4.6,5.4,8.2,4.9C644.3,344.9,646.1,344,648,343.2"/>
		<path id="Fill-51" style={fillBlue} d="M438.2,338.9c0.4-1.1-0.3-1.7-1.8-1.8c-2.2-0.1-4.4,0.1-6.4,1c-1.6,0.7-2.6,1.9-2.3,3.8
			c0.2,1.8,1.5,2.6,3,3C434.4,346.1,438,343.4,438.2,338.9 M438.7,345.1c-3.1,3.4-6.3,3.7-9.8,3.2c-3.2-0.5-5.3-2.5-5.8-5.5
			s0.8-5.7,3.5-7.2c2.9-1.7,6.2-2,9.5-1.7c2.1,0.2,2.2-0.8,1.9-2.3c-0.5-2.1-1.9-3.3-4-3.4c-2.7-0.2-5.3,0.2-7.6,2
			c-1.3-2.9,0-4.1,2.5-4.8c2.2-0.7,4.5-0.8,6.8-0.5c3.7,0.5,6,2.6,6.5,6.2c0.8,5.3,0.1,10.7,0.4,16c0.1,1.5-1.1,1-1.8,1.1
			C438.9,348.5,438.3,347.6,438.7,345.1"/>
		<path id="Fill-53" style={fillBlue} d="M487.7,334.5c1.2,0,2.5-0.1,3.7,0c2,0.2,2.3-0.8,1.8-2.3c-0.8-2.3-2.3-3.8-5-4
			c-3-0.1-4.8,1.3-5.9,4c-0.7,1.5-0.7,2.5,1.4,2.3C485,334.4,486.3,334.5,487.7,334.5 M496,343.3c0.3,4.2,0.1,4.6-3.9,5.1
			c-2.2,0.3-4.4,0.3-6.6-0.1c-6.2-1.2-9.8-7.9-8.1-15.1c1.5-6.5,7.1-9.9,13.4-8.1c4.5,1.2,7.9,7,7,11.5c-0.3,1.4-1.2,1-1.9,1
			c-3.9,0-7.8,0-11.8,0c-2.7,0-3,0.5-1.9,3c1.5,3.6,5.2,5.3,9.5,4.2C493.2,344.5,494.5,343.8,496,343.3"/>
		<path id="Fill-55" style={fillBlue} d="M318.7,328c1.6-1.5,3.3-2.6,5.3-3.1c5.9-1.4,10.4,2,10.6,8c0.1,4.4,0,8.8,0.1,13.2
			c0,1.7-0.6,2.1-2.2,2.1c-1.7,0-2.1-0.6-2-2.2c0.1-3.6,0.1-7.3,0-10.9c-0.1-4.5-1.6-6.7-4.5-6.9c-3.7-0.3-6.2,2-6.9,6.2
			c-0.7,3.8-0.3,7.6-0.3,11.5c0,1.6-0.3,2.4-2.2,2.4c-2,0-2.1-0.9-2.1-2.4c0.1-5.9,0-11.9,0.1-17.8c0-1.1-0.8-2.8,1.6-2.8
			C318.1,325.2,319,325.7,318.7,328"/>
		<path id="Fill-57" style={fillBlue} d="M274.7,325.6c3.5-1.2,4.9,0.1,5.8,3.1c1.3,4.8,3.1,9.6,4.9,15.1c2-5.9,3.7-11.1,5.5-16.3
			c0.3-1,0.4-2.2,2-2.3c2.8-0.1,3,0,2.1,2.6c-2.2,6.2-4.5,12.4-6.6,18.6c-0.5,1.3-1.1,1.9-2.4,1.7c-1.3-0.2-3,0.7-3.7-1.2
			C279.7,339.8,277.2,332.8,274.7,325.6"/>
		<path id="Fill-59" style={fillBlue} d="M516.4,343.9c2.3,0.8,4.3,1.7,6.6,1.1c1.3-0.4,2.3-1,2.5-2.4c0.3-1.4-0.4-2.4-1.6-3.1
			c-1.5-0.9-3-1.6-4.5-2.5c-2.6-1.4-4.1-3.4-3.5-6.5c0.6-3.2,2.7-5.2,6-5.7c1.2-0.2,2.5-0.1,3.7,0c3.7,0.3,3.7,0.4,3,4.2
			c-1.3-0.3-2.6-0.6-3.8-0.8c-1.8-0.2-3.7-0.1-4.3,2s1,3.1,2.6,3.9c1.2,0.6,2.4,1.1,3.6,1.8c3,1.7,4,3.9,3.4,7.2
			c-0.4,2.6-2.8,4.9-5.7,5.3c-1.6,0.2-3.3,0.2-4.9,0C515.8,348.1,515.7,347.8,516.4,343.9"/>
		<path id="Fill-61" style={fillBlue} d="M623.2,331.5c0,5-0.1,9.9,0,14.9c0,1.7-0.6,2.1-2.1,2c-1.3-0.1-2.6,0.4-2.5-1.9
			c0.1-10,0.1-20.1,0-30.1c0-2.1,1-1.9,2.4-2c1.7-0.1,2.3,0.4,2.3,2.2C623.1,321.5,623.2,326.5,623.2,331.5"/>
		<path id="Fill-63" style={fillGray} d="M467.6,285.2c4.8,0,4.8,0,4.8,4.9c0,5.3,0,5.3-5.4,5.3c-4.8,0-4.8,0-4.8-4.9
			C462.3,285.2,462.3,285.2,467.6,285.2"/>
		<path id="Fill-65" style={fillBlue} d="M264.1,336.5c0-3.1,0.1-6.3,0-9.4c-0.1-2,1.1-1.8,2.4-1.8c1.2,0,2.2-0.1,2.1,1.7
			c-0.1,6.5-0.1,13,0,19.5c0,1.8-0.9,1.7-2.2,1.7c-1.4,0-2.4,0-2.3-1.9C264.2,343,264.1,339.8,264.1,336.5"/>
		<path id="Fill-67" style={fillBlue} d="M541.1,336.7c0,3.2-0.1,6.3,0,9.5c0.1,1.8-0.8,2-2.3,2c-1.4,0-2-0.3-2-1.9
			c0.1-6.4,0.1-12.8,0-19.3c0-1.8,0.9-1.8,2.1-1.8c1.2,0,2.3-0.1,2.2,1.7C541.1,330.2,541.1,333.5,541.1,336.7"/>
		<path id="Fill-69" style={fillBlue} d="M305.9,336.8c0,3.2-0.1,6.3,0,9.5c0,1.5-0.5,2-2,1.9c-1.5,0-2.3-0.2-2.2-2
			c0.1-6.4,0.1-12.8,0-19.2c0-1.8,0.9-1.7,2.1-1.7c1.3,0,2.1,0.1,2.1,1.8C305.8,330.3,305.9,333.5,305.9,336.8"/>
		<path id="Fill-71" style={fillBlue} d="M303.7,320.1c-2.5,0.6-2.1-1-2.3-2.6c-0.2-2.3,1.1-2,2.6-2.2c2-0.2,2.3,0.7,2.3,2.4
			C306.3,319.8,305.7,320.6,303.7,320.1"/>
		<path id="Fill-73" style={fillBlue} d="M539,320.1c-2.3,0.6-2.2-0.9-2.3-2.4c-0.2-2,0.8-2.2,2.5-2.3c2.3-0.2,2.2,1,2.4,2.7
			C541.8,320.4,540.5,320.4,539,320.1"/>
		<path id="Fill-75" style={fillBlack} d="M265.9,320.1c-2.2,0.6-2-0.9-2.1-2.5c-0.2-2,0.8-2.2,2.5-2.3c2.3-0.1,2.3,1,2.4,2.7
			C268.8,320.4,267.5,320.3,265.9,320.1"/>
		</g>
	</g>
	</svg>

	)
}

CanadaHelps.defaultProps = {
	width: `150px`,
}

export default CanadaHelps
